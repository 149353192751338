angular.module('LeasePilot').service('SocketService', () => {
  const channels = [];

  const subscribe = function(channelName, resource, recievedCallback) {
    if (_.includes(channels, resource)) return;
    channels.push(resource);

    App.cable.subscriptions.create(
      {
        channel: channelName,
        id: resource.id,
      },
      {
        connected(data) {
          console.log('connected: ', channelName, resource.id);
        },
        disconnected(data) {
          console.log('disconnected: ', channelName, resource.id);
        },
        received(data) {
          if (typeof recievedCallback === 'function') {
            recievedCallback(data);
          }
        },
      },
    );
  };

  return {
    subscribe,
  };
});
